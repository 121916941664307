import { NavigateFunction } from "react-router-dom"

const it = {
    __LANGUAGE: "it",
    CERCA: "Cerca",
    CAMBIA_PASSWORD: "Cambia password",
    SOTTOUTENTI: "Sottoutenti",
    BILLING: "Billing",
    MODULI: "Moduli",
    LOGOUT: "Logout",
    COPIATO: "Copiato!",
    OK: "Ok",
    MODIFICA: "Modifica",
    ELIMINA: "Elimina",
    VISUALIZZA: "Visualizza",
    ORDINA: "Ordina",
    AGGIUNGI: "Aggiungi",
    CARICA: "Carica",
    ANNULLA: "Annulla",
    RIFIUTA: "Rifuta",
    RIPRISTINA: "Ripristina",
    RIGENERA: "Rigenera",
    SELEZIONA_TUTTI: "Seleziona tutti",
    DESELEZIONA_TUTTI: "Deseleziona tutti",
    CONFERMA: "Conferma",
    INVIA: "Invia",
    ATTENDERE: "Attendere, prego",
    ATTENZIONE: "Attenzione",
    ERRORE_GENERICO: "Si è verificato un errore, si prega di riprovare.",
    SELEZIONA_UN_VALORE: "Seleziona un valore",
    SELEZIONA_UN_FILE: "Seleziona un file",
    SI: "Sì",
    NO: "No",
    DA: "Da",
    TUTTI: "Tutti",
    SALES_ORDER: "Ordine di vendita",
    TRANSFER_ORDER:"Ordine di trasferimento",
    SALVA: "Salva",
    E_MAIL: "Email",
    ELIMINAZIONE_IN_CORSO: "Eliminazione in corso...",
    SALVATAGGIO_IN_CORSO: "Salvataggio in corso...",
    OPERAZIONE_AVVENUTA_CON_SUCCESSO: "Operazione avvenuta con successo",
    COPIA_VALORE: "Copia valore",
    PROCEDI: "Procedi",
    PROSEGUI: "Prosegui",
    CAMBIA: "Cambia",
    CREA: "Crea",
    ISTRUZIONI: "Istruzioni",
    DETTAGLI: "Dettagli",
    MOSTRA: "Mostra",
    RISULTATI: "__COUNT__ risultati",
    HOME: "Home",
    INIZIA: "Inizia",
    VERIFICA: "Verifica",
    SELEZIONA_FILE: "Seleziona file",
    SALE_CHANNEL:"Canale di vendita",
    SALES_CHANNEL:"Canali di vendita",
    BADGE_SHOP_PRIMARY:"Shop Primario",
    ARTICOLO: "Articolo",
    QUANTITA: "Quantità",
    PREZZO_UNITARIO: "Prezzo unitario",
    PREZZO_TOTALE: "Prezzo totale",
    SCONTO: "Sconto",
    MARKUP: "Markup",
    TOTALE_ORDINE: "Totale ordine",
    SUBTOTALE: "Subtotale",
    SPESE_DI_SPEDIZIONE: "Spese di spedizione",
    VAI_A_DETTAGLIO: "Vai al dettaglio",
    APRI_ANTEPRIMA: "Apri anteprima",
    GOTO_CHECKOUT: "Vai al checkout",
    LISTINO: "Listino",
    EMPTY: "Svuota",
    CLOSE:"Chiudi",
    CALENDAR:{
        LAST_3M:"Ultimi 3 mesi",
        LAST_6M:"Ultimi 6 mesi",
        THIS_Y: "Anno corrente",
        LAST_Y: "Ultimo anno",
        LAST_2Y: "Ultimi 2 anni",
    },
    CONTACTS: {
        EMAIL: "Email",
        PHONE: "Telefono",
        MOBILE: "Cellulare"
    },
    MENU: {
        CATALOGO: "Catalogo",
        ORDINI: "Ordini",
        ORDINE: "Ordine",
        PROFILO: "Profilo",
        LOGOUT: "Logout",
        RICERCA_AVANZATA: "Ricerca avanzata",
        DETTAGLIO_ARTICOLO: "Dettaglio articolo",
        CHECKOUT: "Checkout",
    },
    FORMS: {
        ERRORI_NEL_FORM: "Ci sono errori nei campi del form, si prega di controllare.",
        CAMPO_OBBLIGATORIO: "Campo obbligatorio",
        LUNGHEZZA_COMPRESA: "La lunghezza del campo deve essere compresa tra __MIN__ e __MAX__ caratteri.",
        VALORE_COMPRESO: "Il valore del campo deve essere compreso tra __MIN__ e __MAX__.",
        ERRORE_REGEX: "Formato non corretto",
    },
    CONFIGURAZIONE: {
        NUOVA_CONFIGURAZIONE: "Nuova configurazione"
    },
    REACT_SELECT: {
        PLACEHOLDER: "Seleziona un valore...",
        NESSUN_RISULTATO: "Nessun risultato trovato."
    },
    COMPONENTS: {
        ADDRESS_SELECTOR: {
            AGGIUNGI_NUOVO_INDIRIZZO: "Aggiungi un tuo nuovo indirizzo",
            MODIFICA_INDIRIZZO_ORDINE: "Modifica l'indirizzo dell'ordine",
            INDIRIZZO_DI_SPEDIZIONE: "Indirizzo di spedizione",
            INDIRIZZO_DI_FATTURAZIONE: "Indirizzo di fatturazione",
            SELEZIONA_INDIRIZZO: "Seleziona un tuo indirizzo",
            NOMINATIVO_CONTATTO: "Nominativo di contatto",
            NOME: "Nome",
            COGNOME: "Cognome",
            RAGIONE_SOCIALE: "Ragione sociale",
            INDIRIZZO: "Indirizzo",
            NAZIONE: "Nazione",
            INDIRIZZO_1: "Indirizzo 1",
            INDIRIZZO_2: "Indirizzo 2",
            CITTA: "Città",
            PROVINCIA: "Provincia",
            SIGLA_PROVINCIA: "Sigla provincia",
            CODICE_POSTALE: "Codice postale",
            EMAIL: "Email",
            TELEFONO: "Telefono",
            CELLULARE: "Cellulare",
            PARTITA_IVA: "Partita iva",
            CODICE_FISCALE: "Codice fiscale",
            ERRORE_TELEFONO: "Formato non corretto (es. corretto: +39123456789)",
            SALVA_NEI_TUOI_INDIRIZZI: "Salva nei tuoi indirizzi",
            PREDEFINITO: "Predefinito",
            ERRORE_NOMINATIVO: "Ragione sociale oppure nome e cognome devono essere presenti",
        },
        SHIPPING_METHOD: {
            CUSTOMER: "Corriere del cliente",
            DHL_EXPRESS: "DHL Express",
            ERRORE_METODO_MANCANTE: "Il metodo di spedizione non è definito",
            ERRORE_METODO_NON_GESTITO: (method: string)=>`Il metodo di spedizione ${method} non è gestito dal sistema`
        },
        PAYMENT_METHOD: {
            BANK_TRANSFER: "Bonifico bancario",
            PAYPAL: "Paypal",
            CREDIT_CARD: "Carta di credito",
            ERRORE_METODO_MANCANTE: "Il metodo di pagamento non è definito",
            ERRORE_METODO_NON_GESTITO: (method: string)=>`Il metodo di pagamento ${method} non è gestito dal sistema`
        }
    },
    PAGES: {
        LOGIN: {
            ACCEDI: "Accedi",
            LOGIN: 'Login',
            RICORDAMI: 'Ricordami',
            CREDENZIALI_NON_RICONOSCIUTE: 'Credenziali non riconosciute',
            PASSWORD_DIMENTICATA: "Password dimenticata?",
            PASSWORD_DIMENTICATA_DESC: "Inserisci la tua email per resettare la tua password.",
            PASSWORD_DIMENTICATA_SUCCESS: "Ti abbiamo inviato una email per reimpostare la tua password. Segui le istruzioni e riprova ad accedere con la nuova password.",
            TORNA_ALLA_LOGIN: "Torna alla login",
        },
        CATALOGO: {
            NESSUN_ARTICOLO_DISPONIBILE: "Nessun articolo corrisponde ai filtri selezionati.",
            ACQUISTA: "Acquista",
            TAGLIA: "Taglia",
            NESSUNA: "Nessuna",
            TUTTE: "Tutte",
            CERCA_ARTICOLO: "Cerca articolo",
            MINIMO_TRE_CARATTERI: "Inserisci almeno tre caratteri.",
            NESSUN_RISULTATO_VAI_A_RICERCA_AVANZATA: "Nessun risultato trovato. Vai alla ricerca avanzata",
            NESSUN_RISULTATO: "Nessun risultato trovato.",
            RISULTATI: (count: number)=>{if(count===1) {return "Risultato"} else {return "risultati"}},
            FILTERS: {
                SELEZIONA_TUTTI: "Seleziona tutti",
                BRAND: "Brand",
                SEASON: "Stagioni",
                SIZE: "Taglie",
                CATEGORIES: "Categorie",
                IMAGES: "Immagini",
                NESSUN_FILTRO: "Nessun filtro",
                WITH_IMAGES: "Con immagini",
                WITHOUT_IMAGES: "Senza immagini",
                FTA: "Fta",
                CARRYOVER: "Carry over",
                COLORS: "Colori",
                RESETTA_FILTRI: "Resetta filtri",
                ORDINAMENTO: {
                    SKU_PARENT_ASC: "Codice articolo (A-Z)",
                    SKU_PARENT_DESC: "Codice articolo (Z-A)",
                    LAST_INFO_UPDATE_ASC: "Modifica meno recente",
                    LAST_INFO_UPDATE_DESC: "Modifica più recente",
                }
            }
        },
        CHECKOUT: {
            IL_TUO_CARRELLO_VUOTO: "Il tuo carrello è vuoto",
            CARRELLO_VUOTO_DESC: (navigate: NavigateFunction) => <div>Dai un'occhiata al <span className="cs-link" onClick={(()=>navigate("/catalog"))}>nostro catalogo</span> e trova qualcosa che fa per te!</div>,
            FATTURAZIONE: "Fatturazione",
            SPEDIZIONE: "Spedizione",
            RIEPILOGO: "Riepilogo",
            METODO_PAGAMENTO: "Metodo di pagamento",
            METODO_SPEDIZIONE: "Metodo di spedizione",
            ARTICOLI_ORDINE: "Elenco articoli nell'ordine",
            NUMERO_ARTICOLI: "Numero articoli",
            TOTALE_ARTICOLI: "Totale articoli",
            IVA: "IVA",
            TOTALE_ORDINE: "Totale ordine",
            ACQUISTA: "Acquista",
            ERRORE_PAGAMENTO: "Si è verificato un errore nella procedura di pagamento, si prega di riprovare.",
            GRAZIE_PER_ACQUISTO: "Grazie per l'acquisto!",
            IDENTIFICATIVO_ORDINE_DESC: (paymentId:string, navigate: NavigateFunction)=><div>Il tuo codice di pagamento è <strong>{paymentId}</strong>. Clicca <span className="cs-link" onClick={(()=>navigate("/catalog"))}>qui</span> per tornare al catalogo.</div>,
            GRAZIE_ACQUISTO_DESC: (navigate: NavigateFunction)=><div>Clicca <span className="cs-link" onClick={(()=>navigate("/catalog"))}>qui</span> per tornare al catalogo.</div>,
            ELABORAZIONE_ORDINE_IN_CORSO: "Elaborazione ordine in corso...",
            ORDINE_NON_TROVATO: "Ordine non trovato",
            PAGAMENTO_ANNULLATO: "Operazione di pagamento annullata.",
            PROBLEMA_PAGAMENTO: "Si è verificato un problema con il pagamento",
            VAI_A_CARRELLO: (navigate: NavigateFunction)=><div>Clicca <span className="cs-link" onClick={(()=>navigate("/cart"))}>qui</span> per tornare al checkout.</div>,
        },
        ORDERS: {
            ARTICOLI: "Articoli",
            SCARICA_ORDINI: "Scarica ordini",
            ORDINE: "Ordine",
            STATO_ORDINE: "Stato",
            DATA: "Data",
            CONTROVALORE: "Totale",
            NUMERO_ARTICOLI: "Numero articoli",
            APRI_ANTEPRIMA: "Apri anteprima",
            VAI_A_DETTAGLIO: "Vai al dettaglio",
            COPIA_ID: "Copia ID",
            COPIA_CODICE_ORDINE: "Copia codice ordine",
            INFORMAZIONI_ACQUIRENTE: "Informazioni acquirente",
            SPEDIZIONE: "Spedizione",
            FATTURAZIONE: "Fatturazione",
            ORDINI_EFFETTUATI: "__COUNT__ Ordini effettuati",
            STATUS_CHANGE: "Cambia stato dell'ordine",
            ORDER_NUMBER: "Numero ordine",
            SPEDITO_A:"Spedito a",
            FROM_SENT_TO:"Da spedire a",
            SEND_TO:"Spedire a",
            STATUS: {
                PENDING: "Aperto",
                CONFIRMED: "Confermato",
                CANCELED: "Cancellato",
                REFURBISHED: "Rinnovato",
                SHIPPED: "Spedito",
                PARTIALLY_SHIPPED: "Parzialmente spedito",
                OTHER: "Altro",
            }
        },
        ORDER:{
            CURRENT_ORDER_TITLE:"Il tuo ordine corrente",
            NO_ORDER1: "Il tuo ordine è vuoto. Dai un'occhiata al ",
            NO_ORDER2:  "e trova qualcosa che fa per te!",
            OUR_CATALOG:"nostro catalogo",
            ORDINE_NUMERO: "Ordine N°",
            DATA: "Data",
            QUANTITA_NELL_ORDINE: "Quantità nell'ordine",
            TOTALE: "Totale",
            SVUOTA: "Svuota",
            VAI_A_CHECKOUT: "Vai al checkout",
            VARIAZIONE_TEXT: "Articolo nell'ordine ha subito una variazione del prezzo a te dedicato. Puoi verificare le condizioni aggiornate nell'elenco qui sotto.",
            VARIAZIONI_TEXT: "Articoli nell'ordine hanno subito una variazione del prezzo a te dedicato. Puoi verificare le condizioni aggiornate nell'elenco qui sotto.",
            NO_ITEMS_IN_ORDER_CURRENT: (navigate: NavigateFunction) => <div>Il tuo ordine è vuoto. Dai un'occhiata al <span className="cs-link" onClick={(()=>navigate("/catalog"))}>nostro catalogo</span> e trova qualcosa che fa per te!</div>,
            NO_ITEMS_IN_ORDER: "L'ordine è vuoto.",
            ELENCO_ARTICOLI_ORDINE: "Elenco articoli nell'ordine",
            INFORMAZIONI_NON_DISPONIBILI: "Informazioni non disponibili.",
            TAGLIA: "Taglia",
            TAGLIE: "Taglie",
            STAGIONE: "Stagione",
            PREZZO_UNITARIO: "Prezzo unitario",
            QUANTITA: "Quantità",
            INDIRIZZO_DI_SPEDIZIONE: "Indirizzo di spedizione",
            NESSUN_INDIRIZZO_SPEDIZIONE: "Nessun indirizzo di spedizione indicato per quest'ordine.",
            SCEGLI_UN_INDIRIZZO: "Scegli un indirizzo",
            ORDINE_VERRA_SPEDITO_A: "Quest'ordine verrà spedito a",
            ORDINE_SPEDITO_A: "Quest'ordine è stato spedito a",
            PREZZO_CORRENTE_AGGIORNATO: "Prezzo corrente aggiornato",
            CONFERMA_RIMOZIONE_ARTICOLO: (sku: string)=> <p>Sei sicuro di voler rimuovere l'articolo <strong>{sku}</strong> dall'ordine?</p>,
            CONFERMA_SVUOTAMENTO_ORDINE: "Sei sicuro di voler svuotare l'ordine?",
            INDIRIZZO_ATTUALE: "Indirizzo attuale",
            NUOVO_INDIRIZZO: "Inserisci un nuovo indirizzo",
            SELEZIONA_UN_ALTRO_INDIRIZZO: "Seleziona un altro indirizzo",
            ACQUISTA_ALTRE_TAGLIE: "Acquista altre taglie",
        },
        ITEM: {
            MAGGIORI_INFORMAZIONI: "Maggiori informazioni",
            VARIAZIONI_DISPONIBILI: "Variazioni disponibili",
            NASCONDI_ESAURITE: "Nascondi esaurite",
            MORE_INFO: {
                BRAND: "Brand",
                COLOR: "Colore",
                COMPOSITION: "Composizione",
                DESCRIZIONE: "Descrizione",
                HS_CODE: "HS Code",
                SEASON: "Stagione",
                MADE_IN: "Made in",
                GENDER: "Gender",
                FIRST_CATEGORY: "First category",
                SECOND_CATEGORY: "Second category",
                SCALA_TAGLIE: "Scala taglie",
                TITOLO: "Titolo",
                DIMENSIONI: "Dimensioni",
                CARRY_OVER: "Carry Over",
            }
        },
        ACCESS_DENIED: {
            ACCESSO_NEGATO: "Accesso negato",
            ACCESSO_NEGATO_DESCRIZIONE: "Non hai l'autorizzazione per accedere a questa risorsa."
        },
        MAINTENANCE: {
            MANUTENZIONE_IN_CORSO: "Manutenzione in corso",
            MANUTENZIONE_IN_CORSO_DESCRIZIONE: "Stiamo eseguendo delle operazioni di manutenzione, ma torneremo presto online."
        }
    },
    ITEMS:{
        NO_ITEM_PRESENT: "Non ci sono articoli nell'ordine.",
        ITEM_NOT_PRESENT: "Articolo non presente nell'inventario.",
        ITEMS_ORDER:"Articoli in questo ordine",
        SKU:"Sku",
        BRAND:"Brand",
        SEASON:"Stagione",
        TITLE:"Titolo",
        QT_ROW:"Quantità riga ordine",
        TOT_ROW:"Costo totale di riga",
        SIZE:"Taglia",
        COLOR:"Colore",
        QTY:"Quantità",
        PRICE:"Prezzo",
    },
    FILTERS: {
        SELEZIONARE_ALMENO_UN_FILTRO: "Select at least one filter to view results.",
        NESSUN_RISULTATO: "No results found.",
        ERRORE: "An error occurred, please try again."
    },
    LANGUAGES:{
        IT: "italiano",
        DE: "tedesco",
        DK: "danese",
        ES: "spagnolo",
        EU: "unione europea",
        FR: "francese",
        EN: "inglese",
        RU: 'russo',
        SE: "svedese",
        CN: "cinese"
    },
    COUNTRIES_CODE: {
        AC: "Isola di Ascensione",
        AD: "Andorra",
        AE: "Emirati Arabi Uniti",
        AF: "Afghanistan",
        AG: "Antigua e Barbuda",
        AI: "Anguilla",
        AL: "Albania",
        AM: "Armenia",
        AO: "Angola",
        AQ: "Antartide",
        AR: "Argentina",
        AS: "Samoa Americane",
        AT: "Austria",
        AU: "Australia",
        AW: "Aruba",
        AX: "Isole Åland",
        AZ: "Azerbaigian",
        BA: "Bosnia ed Erzegovina",
        BB: "Barbados",
        BD: "Bangladesh",
        BE: "Belgio",
        BF: "Burkina Faso",
        BG: "Bulgaria",
        BH: "Bahrein",
        BI: "Burundi",
        BJ: "Benin",
        BL: "Saint-Barthélemy",
        BM: "Bermuda",
        BN: "Brunei",
        BO: "Bolivia",
        BQ: "Caraibi Olandesi",
        BR: "Brasile",
        BS: "Bahamas",
        BT: "Bhutan",
        BV: "Isola di Bouvet",
        BW: "Botswana",
        BY: "Bielorussia",
        BZ: "Belize",
        CA: "Canada",
        CC: "Isole Cocos",
        CD: "Congo (Kinshasa)",
        CF: "Repubblica Centrafricana",
        CG: "Congo (Brazzaville)",
        CH: "Svizzera",
        CI: "Costa d'Avorio",
        CK: "Isole Cook",
        CL: "Cile",
        CM: "Camerun",
        CN: "Cina",
        CO: "Colombia",
        CR: "Costa Rica",
        CU: "Cuba",
        CV: "Capo Verde",
        CW: "Curaçao",
        CX: "Isola di Natale",
        CY: "Cipro",
        CZ: "Repubblica Ceca",
        DE: "Germania",
        DJ: "Gibuti",
        DK: "Danimarca",
        DM: "Dominica",
        DO: "Repubblica Dominicana",
        DZ: "Algeria",
        EC: "Ecuador",
        EE: "Estonia",
        EG: "Egitto",
        EH: "Sahara Occidentale",
        ER: "Eritrea",
        ES: "Spagna",
        ET: "Etiopia",
        FI: "Finlandia",
        FJ: "Figi",
        FK: "Isole Falkland",
        FM: "Micronesia",
        FO: "Isole Faroe",
        FR: "Francia",
        GA: "Gabon",
        GB: "Regno Unito",
        GD: "Grenada",
        GE: "Georgia",
        GF: "Guyana Francese",
        GG: "Guernsey",
        GH: "Ghana",
        GI: "Gibilterra",
        GL: "Groenlandia",
        GM: "Gambia",
        GN: "Guinea",
        GP: "Guadalupa",
        GQ: "Guinea Equatoriale",
        GR: "Grecia",
        GS: "Georgia del Sud e Isole Sandwich Meridionali",
        GT: "Guatemala",
        GU: "Guam",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HK: "Hong Kong",
        HM: "Isole Heard e McDonald",
        HN: "Honduras",
        HR: "Croazia",
        HT: "Haiti",
        HU: "Ungheria",
        ID: "Indonesia",
        IE: "Irlanda",
        IL: "Israele",
        IM: "Isola di Man",
        IN: "India",
        IO: "Territorio Britannico dell'Oceano Indiano",
        IQ: "Iraq",
        IR: "Iran",
        IS: "Islanda",
        IT: "Italia",
        JE: "Jersey",
        JM: "Giamaica",
        JO: "Giordania",
        JP: "Giappone",
        KE: "Kenya",
        KG: "Kirghizistan",
        KH: "Cambogia",
        KI: "Kiribati",
        KM: "Comore",
        KN: "Saint Kitts e Nevis",
        KP: "Corea del Nord",
        KR: "Corea del Sud",
        KW: "Kuwait",
        KY: "Isole Cayman",
        KZ: "Kazakistan",
        LA: "Laos",
        LB: "Libano",
        LC: "Santa Lucia",
        LI: "Liechtenstein",
        LK: "Sri Lanka",
        LR: "Liberia",
        LS: "Lesotho",
        LT: "Lituania",
        LU: "Lussemburgo",
        LV: "Lettonia",
        LY: "Libia",
        MA: "Marocco",
        MC: "Monaco",
        MD: "Moldavia",
        ME: "Montenegro",
        MF: "Saint Martin",
        MG: "Madagascar",
        MH: "Isole Marshall",
        MK: "Macedonia del Nord",
        ML: "Mali",
        MM: "Myanmar",
        MN: "Mongolia",
        MO: "Macao",
        MP: "Isole Marianne Settentrionali",
        MQ: "Martinica",
        MR: "Mauritania",
        MS: "Montserrat",
        MT: "Malta",
        MU: "Mauritius",
        MV: "Maldive",
        MW: "Malawi",
        MX: "Messico",
        MY: "Malesia",
        MZ: "Mozambico",
        NA: "Namibia",
        NC: "Nuova Caledonia",
        NE: "Niger",
        NF: "Isola Norfolk",
        NG: "Nigeria",
        NI: "Nicaragua",
        NL: "Paesi Bassi",
        NO: "Norvegia",
        NP: "Nepal",
        NR: "Nauru",
        NU: "Niue",
        NZ: "Nuova Zelanda",
        OM: "Oman",
        PA: "Panama",
        PE: "Perù",
        PF: "Polinesia Francese",
        PG: "Papua Nuova Guinea",
        PH: "Filippine",
        PK: "Pakistan",
        PL: "Polonia",
        PM: "Saint Pierre e Miquelon",
        PN: "Pitcairn",
        PR: "Porto Rico",
        PS: "Palestina",
        PT: "Portogallo",
        PW: "Palau",
        PY: "Paraguay",
        QA: "Qatar",
        RE: "Riunione",
        RO: "Romania",
        RS: "Serbia",
        RU: "Russia",
        RW: "Ruanda",
        SA: "Arabia Saudita",
        SB: "Isole Salomone",
        SC: "Seychelles",
        SD: "Sudan",
        SE: "Svezia",
        SG: "Singapore",
        SH: "Sant'Elena",
        SI: "Slovenia",
        SJ: "Svalbard e Jan Mayen",
        SK: "Slovacchia",
        SL: "Sierra Leone",
        SM: "San Marino",
        SN: "Senegal",
        SO: "Somalia",
        SR: "Suriname",
        SS: "Sud Sudan",
        ST: "Sao Tome e Principe",
        SV: "El Salvador",
        SX: "Sint Maarten",
        SY: "Siria",
        SZ: "Swaziland",
        TC: "Isole Turks e Caicos",
        TD: "Ciad",
        TF: "Territori Francesi del Sud",
        TG: "Togo",
        TH: "Thailandia",
        TJ: "Tagikistan",
        TK: "Tokelau",
        TL: "Timor Est",
        TM: "Turkmenistan",
        TN: "Tunisia",
        TO: "Tonga",
        TR: "Turchia",
        TT: "Trinidad e Tobago",
        TV: "Tuvalu",
        TW: "Taiwan",
        TZ: "Tanzania",
        UA: "Ucraina",
        UG: "Uganda",
        UM: "Isole Minori Esterne degli Stati Uniti",
        US: "Stati Uniti d'America",
        UY: "Uruguay",
        UZ: "Uzbekistan",
        VA: "Città del Vaticano",
        VC: "Saint Vincent e Grenadine",
        VE: "Venezuela",
        VG: "Isole Vergini Britanniche",
        VI: "Isole Vergini Americane",
        VN: "Vietnam",
        VU: "Vanuatu",
        WF: "Wallis e Futuna",
        WS: "Samoa",
        XK: "Kosovo",
        YE: "Yemen",
        YT: "Mayotte",
        ZA: "Sudafrica",
        ZM: "Zambia",
        ZW: "Zimbabwe",
        UNDEFINED: "Non definito"
    },
    CURRENCY : {
        AED: "Dirham degli Emirati Arabi Uniti",
        AFN: "Afghani",
        ALL: "Lek albanese",
        AMD: "Dram armeno",
        ANG: "Fiorino delle Antille Olandesi",
        AOA: "Kwanza angolano",
        ARS: "Peso argentino",
        AUD: "Dollaro australiano",
        AWG: "Fiorino di Aruba",
        AZN: "Manat azero",
        BAM: "Marco convertibile della Bosnia-Erzegovina",
        BBD: "Dollaro di Barbados",
        BDT: "Taka del Bangladesh",
        BGN: "Lev bulgaro",
        BHD: "Dinaro del Bahrein",
        BIF: "Franco del Burundi",
        BMD: "Dollaro delle Bermuda",
        BND: "Dollaro del Brunei",
        BOB: "Boliviano",
        BRL: "Real brasiliano",
        BSD: "Dollaro delle Bahamas",
        BTN: "Ngultrum del Bhutan",
        BWP: "Pula del Botswana",
        BYN: "Rublo bielorusso",
        BZD: "Dollaro del Belize",
        CAD: "Dollaro canadese",
        CDF: "Franco congolese",
        CHF: "Franco svizzero",
        CLP: "Peso cileno",
        CNY: "Renminbi cinese",
        COP: "Peso colombiano",
        CRC: "Colón costaricano",
        CUC: "Peso cubano convertibile",
        CUP: "Peso cubano",
        CVE: "Escudo capoverdiano",
        CZK: "Corona ceca",
        DJF: "Franco di Gibuti",
        DKK: "Corona danese",
        DOP: "Peso dominicano",
        DZD: "Dinaro algerino",
        EGP: "Sterlina egiziana",
        ERN: "Nakfa eritreo",
        ETB: "Birr etiope",
        EUR: "Euro",
        FJD: "Dollaro delle Figi",
        FKP: "Sterlina delle Falkland",
        GBP: "Sterlina britannica",
        GEL: "Lari georgiano",
        GHS: "Cedi ghanese",
        GIP: "Sterlina di Gibilterra",
        GMD: "Dalasi del Gambia",
        GNF: "Franco della Guinea",
        GTQ: "Quetzal guatemalteco",
        GYD: "Dollaro della Guyana",
        HKD: "Dollaro di Hong Kong",
        HNL: "Lempira honduregno",
        HRK: "Kuna croata",
        HTG: "Gourde haitiano",
        HUF: "Fiorino ungherese",
        IDR: "Rupia indonesiana",
        ILS: "Nuovo siclo israeliano",
        INR: "Rupia indiana",
        IQD: "Dinaro iracheno",
        IRR: "Rial iraniano",
        ISK: "Corona islandese",
        JMD: "Dollaro giamaicano",
        JOD: "Dinaro giordano",
        JPY: "Yen giapponese",
        KES: "Scellino keniano",
        KGS: "Som kirghiso",
        KHR: "Riel cambogiano",
        KMF: "Franco delle Comore",
        KPW: "Won nordcoreano",
        KRW: "Won sudcoreano",
        KWD: "Dinaro kuwaitiano",
        KYD: "Dollaro delle Isole Cayman",
        KZT: "Tenge kazako",
        LAK: "Kip laotiano",
        LBP: "Sterlina libanese",
        LKR: "Rupia dello Sri Lanka",
        LRD: "Dollaro liberiano",
        LSL: "Loti del Lesotho",
        LYD: "Dinaro libico",
        MAD: "Dirham marocchino",
        MDL: "Leu moldavo",
        MGA: "Ariary malgascio",
        MKD: "Denar macedone",
        MMK: "Kyat birmano",
        MNT: "Tugrik mongolo",
        MOP: "Pataca di Macao",
        MRU: "Ouguiya della Mauritania",
        MUR: "Rupia mauriziana",
        MVR: "Rufiyaa delle Maldive",
        MWK: "Kwacha malawiano",
        MXN: "Peso messicano",
        MYR: "Ringgit malese",
        MZN: "Metical mozambicano",
        NAD: "Dollaro namibiano",
        NGN: "Naira nigeriana",
        NIO: "Córdoba nicaraguense",
        NOK: "Corona norvegese",
        NPR: "Rupia nepalese",
        NZD: "Dollaro neozelandese",
        OMR: "Rial omanita",
        PAB: "Balboa panamense",
        PEN: "Sol peruviano",
        PGK: "Kina papuana",
        PHP: "Peso filippino",
        PKR: "Rupia pakistana",
        PLN: "Zloty polacco",
        PYG: "Guaraní paraguaiano",
        QAR: "Rial qatariota",
        RON: "Leu rumeno",
        RSD: "Dinaro serbo",
        RUB: "Rublo russo",
        RWF: "Franco ruandese",
        SAR: "Riyal saudita",
        SBD: "Dollaro delle Isole Salomone",
        SCR: "Rupia delle Seychelles",
        SDG: "Sterlina sudanese",
        SEK: "Corona svedese",
        SGD: "Dollaro di Singapore",
        SHP: "Sterlina di Sant'Elena",
        SLL: "Leone della Sierra Leone",
        SOS: "Scellino somalo",
        SRD: "Dollaro del Suriname",
        SSP: "Sterlina del Sud Sudan",
        STN: "Dobra di São Tomé e Príncipe",
        SYP: "Sterlina siriana",
        SZL: "Lilangeni dello Swaziland",
        THB: "Baht thailandese",
        TJS: "Somoni tagiko",
        TMT: "Manat turkmeno",
        TND: "Dinaro tunisino",
        TOP: "Paʻanga tongano",
        TRY: "Lira turca",
        TTD: "Dollaro di Trinidad e Tobago",
        TWD: "Nuovo dollaro taiwanese",
        TZS: "Scellino tanzaniano",
        UAH: "Hryvnia ucraina",
        UGX: "Scellino ugandese",
        USD: "Dollaro statunitense",
        UYU: "Peso uruguaiano",
        UZS: "Som uzbeko",
        VES: "Bolívar sovrano",
        VND: "Dong vietnamita",
        VUV: "Vatu di Vanuatu",
        WST: "Tala samoano",
        XAF: "Franco CFA BEAC",
        XCD: "Dollaro caraibico orientale",
        XOF: "Franco CFA BCEAO",
        XPF: "Franco CFP",
        YER: "Rial yemenita",
        ZAR: "Rand sudafricano",
        ZMW: "Kwacha zambiano",
        ZWL: "Dollaro zimbabwese"
    }
}

export default it